




























































.payment-failed {
  text-align: center;
  max-width: 640px;
  padding: 0 20px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 24px;
  }

  .primary-button {
    margin-top: 20px;
  }
}
