



































.receipt-presentation {
  margin: 0 12px;
}
