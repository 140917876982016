






































































.receipt-tippie-feedback {
  h2 {
    color: #000;
    font-weight: bold;
  }
  text-align: center;
  img {
    margin: 0 auto;
    display: block;
    width: 80px;
  }

  .social-items {
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .social-item {
      height: 48px;
      width: 48px;

      img {
        max-width: 100%;
        width: 48px;
      }
    }
  }
}
