






















































































.receipt-header {
  background: #f6f7f9;
  padding: 24px 32px 32px 32px;

  &-text {
    position: relative;

    h1::v-deep {
      span {
        color: #ff8a00;
      }
    }

    &-border {
      &-item {
        position: absolute;

        &:first-child {
          top: 0;
          left: 0;
        }

        &:nth-child(2) {
          top: 0;
          right: 0;
          transform: rotate(90deg);
        }

        &:nth-child(3) {
          bottom: 0;
          left: 0;
          transform: rotate(-90deg);
        }

        &:last-child {
          bottom: 0;
          right: 0;
          transform: rotate(180deg);
        }
      }
    }

    h1 {
      color: #000000;
      font-family: "Raleway Bold", serif;
      font-size: 32px;
      text-align: center;
      line-height: 44px;
      margin: 0;
      padding: 40px 16px 20px 16px;
    }

    &-logo {
      img {
        display: flex;
        margin: 0 auto;
      }
    }
  }
}
