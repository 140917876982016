



















.receipt-rating {
  text-align: center;
  margin: 0 auto 30px;
  font-size: 32px;
  gap: 4px;

  p {
    margin: 20px 0 0;
    font-size: 16px;
    font-weight: bold;
  }

  &-items {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-icon-star-on,
    .el-icon-star-off {
      color: orange;
    }

    .el-icon-star-on {
      font-size: 37px;
    }
  }
}
