











































































































































































































































































.receipt-page {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;

  .receipt-company-name {
    text-align: center;
    font-size: 28px;
    color: #000;
    font-weight: bold;
    margin: 20px 0;
  }

  .body {
    &-title {
      margin: 32px 0 0 9px;

      h2 {
        color: #2d2e2f;
        font-size: 20px;
        line-height: 20px;
        font-family: "Raleway Bold", serif;
      }
    }

    &-buttons {
      padding: 0 12px;

      &-divider {
        height: 1.5px;
        background-color: #e3e5e5;
        margin: 30px 0;
      }
    }

    .primary-button {
      height: 48px;
      font-weight: bold;
    }
  }
}
