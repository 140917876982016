























































































.receipt-review {
  .review {
    &-button::v-deep {
      min-height: 48px;
      width: 100%;
      border: 1px solid #000000;
      border-radius: 10px;
      margin-bottom: 10px;

      span {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
    }

    &-title {
      color: #2d2e2f;
      font-size: 20px;
      line-height: 20px;
      font-family: "Raleway Bold", serif;
      text-align: center;
    }
  }
}
