




























































































.receipt-transcation-details {
  display: flex;
  justify-content: center;

  &-row {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    &:last-child {
      margin-bottom: 24px;
    }

    &-title {
      color: #b5b5b5;
      font-family: "Raleway Regular", serif;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
    }

    &-value {
      color: #2d2e2f;
      font-family: "Raleway Regular", serif;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: "pnum" on, "lnum" on;
    }
  }
}
